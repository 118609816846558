// 多文件上传

<template>
  <div class="upload">
    <el-upload
      ref="uploadFile"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :limit="limit"
      :file-list="fileList"
      class="uploader-file"
      :before-upload="beforeUpload"
      :on-exceed="onExceed"
      :on-success="onSuccess"
      :on-remove="onRemove"
    >
      <slot name="btn"></slot>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    list: { type: Array, default: null },
    accept: { type: String, default: '' },
    limit: { type: [Number, String], default: 10 },
    size: { type: [Number, String], default: 10 },
    disabled: { type: Boolean, default: false },
    tip: { type: String, default: '' }
  },

  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      action: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload',

      fileList: []
    }
  },

  computed: {},

  watch: {
    list: {
      handler(list) {
        if (list) {
          this.fileList = list
        } else {
          this.fileList = []
        }
      },
      immediate: true
      // deep: true
    }
  },

  created() {},

  methods: {
    // 上传前触发
    beforeUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < this.size

      if (!isLtSize) {
        this.$message.error('上传文件的大小不能超过 ' + this.size + 'MB!')
      }

      return isLtSize
    },

    // 文件个数超出限制时触发
    onExceed() {
      this.$message.error('最多只能上传 ' + this.limit + ' 个文件！请删除后再试。')
    },

    // 上传成功时触发
    onSuccess(res, file) {
      this.fileList.push({
        name: file.name,
        url: res.data[0]
      })

      this.$emit('change', this.fileList)
    },

    // 删除文件
    onRemove(file) {
      const arr = [...this.fileList]

      arr.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1)
          this.$emit('change', this.fileList)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  line-height: normal;

  /deep/ .uploader-file {
    .el-upload {
    }

    .el-upload__tip {
    }
  }
}
</style>
